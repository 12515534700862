export function resetAllStores() {
    useFlowStore().reset();
    useShopStore().reset();
    usePassengerStore().reset();
    useCartStore().reset();
    useBookingStore().reset();
    useUpsellingFlowStore().reset();
    useHotelStore().reset();
    useTripStore().reset();
    localStorage.clear();
    localStorage.setItem('tot_last_interaction', zurichDate().toISOString());
}
